import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageCard from '../components/PageCard';
import { JobsContent } from '../content/content';
import styled from 'styled-components';
import { styles, pageTransition } from '../theme/theme';
import background from '../images/jobs.jpg';
import { motion } from 'framer-motion';

const Jobs = () => {
	return (
		<JobsContainer
			initial={pageTransition.initial}
			animate={pageTransition.animate}
			exit={pageTransition.exit}
		>
			<Helmet>
				<title>Slavek Kluczynski | Jobs</title>
				<meta
					name="description"
					content="Haben Sie Erfahrung im Baugewerbe und sind Sie auf der Suche nach einer Stelle? Wenn Sie eine Stelle in der Region Freiburg suchen, nehmen Sie bitte Kontakt mit uns auf. Das Stellenangebot hängt immer von der Saison ab, es ist also immer möglich, dass wir eine Stelle für Ihr Profil haben."
				/>
				<meta
					name="keywords"
					content="jobs, job, arbeit, Baustelle, Bauunternemen, Maurer"
				/>
				<meta name="robots" content="index, follow" />
				<link rel="canonical" href="https://slavek-kluczynski.de/jobs" />
			</Helmet>
			<PageCard
				title={JobsContent.title}
				content={JobsContent.content}
				image={background}
			/>
		</JobsContainer>
	);
};

const JobsContainer = styled(motion.div)`
	display: ${styles.container.display};
	flex-direction: ${styles.container.flexDirection};
	min-height: ${styles.container.minHeight};
	max-width: ${styles.container.maxWidth};
	height: ${styles.container.height};
	align-items: ${styles.container.alignItems};
	background-image: url(${background});
	background-position: ${styles.container.backgroundPosition};
	background-size: ${styles.container.backgroundSize};
`;

export default Jobs;
