import styled from 'styled-components';
import { colors } from '../theme/theme';
import Logo from '../images/logo.png';

const Header = () => {
	return (
		<HeaderContainer className="header">
			<main>
				<LogoImg src={Logo} alt="Slavek Logo" />
				<TitleContainer>
					<Title>Slavek Kluczynski</Title>
					<Subtitle>Renovierung und Innenausbau rund ums Haus</Subtitle>
				</TitleContainer>
			</main>
		</HeaderContainer>
	);
};

const HeaderContainer = styled.header`
	background-color: #f7f7f7;
	padding: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: ${colors.white};
	min-height: 15vh;
	main {
		display: flex;
		max-width: 1000px;
		height: 100%;
		align-items: center;
		justify-content: center;
		@media (min-width: 1000px) {
			justify-content: flex-start;
			width: 1000px;
		}
	}
`;

const LogoImg = styled.img`
	width: 10rem;
`;
const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 1.5rem;
	flex: 2;
`;
const Title = styled.h1`
	font-size: 3rem;
	font-family: 'Montserrat', sans-serif;
	color: ${colors.accent};
	text-align: left;
`;

const Subtitle = styled.h2`
	font-size: 1.5rem;
	font-family: 'Montserrat', sans-serif;
	color: ${colors.black};
	text-align: left;
	font-style: italic;
`;

export default Header;
