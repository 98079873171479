import React from 'react';
import { Helmet } from 'react-helmet-async';
import { styles, pageTransition } from '../theme/theme';
import styled from 'styled-components';
import background from '../images/altbausanierung.jpg';
import { AltbausanierungContent } from '../content/content';
import PageCard from '../components/PageCard';
import { motion } from 'framer-motion';

const Altbausanierung = () => {
	return (
		<AltbausanierungContainer
			initial={pageTransition.initial}
			animate={pageTransition.animate}
			exit={pageTransition.exit}
		>
			<Helmet>
				<title>Slavek Kluczynski | Altbausanierung</title>
				<meta
					name="description"
					content="Die Renovierung des alten Hauses ist oft eher eine Notwendigkeit als ein Wunsch nach Veränderung. Manchmal wird mehr Zeit für diese Aufgabe aufgewendet, weil der Renovierung alter Häuser besondere Aufmerksamkeit gewidmet werden muss. Es braucht Zeit, um ein gutes Ergebnis zu erzielen und die ungefähren Kosten einer solchen Renovierung zu verstehen."
				/>
				<meta
					name="keywords"
					content="Altbausanierung, Renovierung, Renovierungen, Renovierungen Altbau, Altbau, Altbau Renovierung, Altbau Renovierungen, Altbau Renovierungen Slavek Kluczynski, Slavek Kluczynski Altbausanierung, Slavek Kluczynski Renovierung, Slavek Kluczynski Renovierungen, Slavek Kluczynski Renovierungen Altbau, Slavek Kluczynski Renovierungen Altbau Renovierung, Slavek Kluczynski Renovierungen Altbau Renovierungen"
				/>
				<meta name="robots" content="index, follow" />
				<link
					rel="canonical"
					href="https://slavek-kluczynski.de/altbausanierung"
				/>
			</Helmet>
			<PageCard
				title={AltbausanierungContent.title}
				content={AltbausanierungContent.content}
				image={background}
			/>
		</AltbausanierungContainer>
	);
};

const AltbausanierungContainer = styled(motion.div)`
	display: ${styles.container.display};
	flex-direction: ${styles.container.flexDirection};
	min-height: ${styles.container.minHeight};
	max-width: ${styles.container.maxWidth};
	height: ${styles.container.height};
	align-items: ${styles.container.alignItems};
	background-image: url(${background});
	background-position: ${styles.container.backgroundPosition};
	background-size: ${styles.container.backgroundSize};
`;

export default Altbausanierung;
