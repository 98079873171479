import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageCard from '../components/PageCard';
import { TrockenbauContent } from '../content/content';
import styled from 'styled-components';
import { styles, pageTransition } from '../theme/theme';
import background from '../images/trockenbau.jpg';
import { motion } from 'framer-motion';

const Trockenbau = () => {
	return (
		<TrockenbauContainer
			initial={pageTransition.initial}
			animate={pageTransition.animate}
			exit={pageTransition.exit}
		>
			<Helmet>
				<title>Slavek Kluczynski | Trockenbau</title>
				<meta
					name="description"
					content="Der Trockenbau ist ein offenes Baumodell, bei dem die Materialien keine nassen Bindemittel für den Zusammenbau von Strukturen oder anderen Komponenten benötigen. Trockenbau und Stahlskelettbau sind zwei der bekanntesten Systeme. Trockenbau ist ein System, bei dem sehr dünne Stahlprofile für die Montage von Innentrennwänden verwendet werden, die keinen strukturellen Belastungen ausgesetzt sind, wie Trennwände, Decken und Verkleidungen. Stahlskelettbau hingegen wird für den Bau von tragenden Strukturen im Wohnungsbau, für Zwischengeschosse, Außenverkleidungen und Industriebauten (u. a.) verwendet, wobei die verwendeten Profile trotz ihres geringen Gewichts strukturelle Lasten tragen."
				/>
				<meta
					name="keywords"
					content="Trockenbau, Freiburg, Trockenbau in Freiburg"
				/>
				<meta name="robots" content="index, follow" />
				<link rel="canonical" href="https://slavek-kluczynski.de/trockenbau" />
			</Helmet>
			<PageCard
				title={TrockenbauContent.title}
				content={TrockenbauContent.content}
				image={background}
			/>
		</TrockenbauContainer>
	);
};

const TrockenbauContainer = styled(motion.div)`
	display: ${styles.container.display};
	flex-direction: ${styles.container.flexDirection};
	min-height: ${styles.container.minHeight};
	max-width: ${styles.container.maxWidth};
	height: ${styles.container.height};
	align-items: ${styles.container.alignItems};
	background-image: url(${background});
	background-position: ${styles.container.backgroundPosition};
	background-size: ${styles.container.backgroundSize};
`;

export default Trockenbau;
