import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../theme/theme';

const ContactButton = () => {
	return (
		<ContactButtonContainer>
			<h5>Lassen Sie uns sprechen</h5>
			<Button>
				<ButtonLink to="/kontakt">Kontakt</ButtonLink>
			</Button>
		</ContactButtonContainer>
	);
};

const ContactButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 10rem;
	margin-top: 2rem;
	align-items: center;
	justify-content: center;
	background-color: ${colors.black};
	h5 {
		font-size: 1.5rem;
		color: ${colors.white};
	}
`;

const Button = styled.div`
	display: flex;
	width: 15rem;
	height: 5rem;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
`;

const ButtonLink = styled(Link)`
	display: flex;
	text-decoration: none;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: ${colors.accent};
	color: ${colors.white};
	font-size: 1.7rem;
	font-weight: bold;
	transition: background-color 0.3s ease-in-out;
	transition: color 0.3s ease-in-out;

	&:hover {
		background-color: ${colors.white};
		color: ${colors.accent};
		transition: background-color 0.3s ease-in-out;
		transition: color 0.3s ease-in-out;
	}
`;

export default ContactButton;
