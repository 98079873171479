import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme/theme';
import { ImWhatsapp } from 'react-icons/im';
import { BsCheck2All } from 'react-icons/bs';
import { FaArrowAltCircleDown } from 'react-icons/fa';
import ContactButton from './ContactButton';

const PageSectionCard = ({ content, image }) => {
	// get the actual hour and minute

	const actualTime = () => {
		const date = new Date();
		const hour = date.getHours();
		const minute = date.getMinutes();
		if (hour < 10 && minute < 10) {
			return `0${hour}:${minute}`;
		}
		if (hour < 10 && minute >= 10) {
			return `0${hour}:0${minute}`;
		}
		if (hour >= 10 && minute < 10) {
			return `${hour}:0${minute}`;
		}
		if (hour >= 10 && minute >= 10) {
			return `${hour}:${minute}`;
		}
	};
	return (
		<CardContainer>
			<h4>{content.title}</h4>
			<ImageContainer>
				<img src={image} alt={content.title} />
			</ImageContainer>
			<p className={content.phone ? 'contact-text' : 'page-text'}>
				{content.text}
				{content.phone && <FaArrowAltCircleDown color={colors.white} />}
				<br />
				{content.phone ? (
					<span>
						{actualTime()} <BsCheck2All />
					</span>
				) : null}
			</p>
			{content.antsprechtTitle && (
				<p>
					<strong>{content.antsprechtTitle}:</strong> <br />
					{content.antsprechtName}
				</p>
			)}

			{content.phone ? (
				<PhoneContainer>
					<IconsContainer>
						<ImWhatsapp size={30} color={colors.whatsapp} />
					</IconsContainer>
					<a href={`tel: +${content.phoneMeta}`}>{content.phone}</a>
				</PhoneContainer>
			) : (
				<ContactButton />
			)}
		</CardContainer>
	);
};

const CardContainer = styled.section`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 90%;
	align-items: center;
	padding: 2rem;
	background-color: ${colors.white};
	@media (min-width: 850px) {
		padding: 4rem;
	}
	h4 {
		display: flex;
		font-size: 1.8rem;
		align-items: center;
		justify-content: center;
		color: ${colors.accent};
		margin: 1.5rem 0;
	}
	p {
		font-size: 1.7rem;
		font-weight: 500;
		line-height: 2;
		column-count: 2;
		column-gap: 3rem;
		column-width: 350px;
		margin: 2rem 0.5rem;
		@media (min-width: 850px) {
			margin: 4rem;
		}
	}
	.contact-text {
		position: relative;
		width: 100%;
		padding: 1rem;
		border-radius: 1rem;
		color: ${colors.white};
		background-color: ${colors.whatsapp};
		box-shadow: 0 0.5rem 1rem ${colors.boxShadow};
		@media (min-width: 570px) {
			width: 70%;
		}
		@media (min-width: 840px) {
			width: 50%;
		}
		span {
			display: flex;
			width: 100%;
			height: fit-content;
			align-items: center;
			justify-content: flex-end;
			margin-top: 1rem;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: -1.5rem;
			border-top: 3rem solid ${colors.whatsapp};
			border-left: 2rem solid transparent;
			border-right: 2rem solid transparent;
			z-index: 15;
		}
	}
`;

const ImageContainer = styled.div`
	display: flex;
	width: 100%;
	height: 10vh;
	img {
		width: 100%;
		object-fit: cover;
	}
`;

const PhoneContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${colors.accent};
	height: 12vh;
	margin: 1.5rem 0 3rem 0;
	width: 100%;
	box-shadow: 0 0.5rem 1rem ${colors.boxShadow};
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.8rem;
		font-weight: bold;
		color: ${colors.white};
		text-align: center;
		text-decoration: none;
	}
`;

const IconsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 10rem;
	margin-bottom: 1rem;
	background-color: ${colors.white};
	padding: 0.5rem;
	border-radius: 0.5rem;
`;

export default PageSectionCard;
