import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../theme/theme';
import { CgMenuRound, CgCloseO } from 'react-icons/cg';

const NavBar = () => {
	const [isOpen, setIsOpen] = useState(false);

	const MenuOpen = (
		<CgMenuRound
			className="menu-open"
			onClick={() => setIsOpen(true)}
			size="40px"
			color="white"
		/>
	);

	const MenuClose = (
		<CgCloseO
			className="menu-close"
			onClick={() => setIsOpen(false)}
			size="40px"
			color="white"
		/>
	);

	return (
		<NavigationContainer>
			{isOpen === false ? MenuOpen : MenuClose}

			<Navigation className={isOpen ? 'mov-nav-open' : 'mov-nav-closed'}>
				<LinkContainer>
					<LinkElement to="/" onClick={() => setIsOpen(false)}>
						Wir
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement to="/renovierung" onClick={() => setIsOpen(false)}>
						Renovierung
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement to="/trockenbau" onClick={() => setIsOpen(false)}>
						Trockenbau
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement to="/fenstertueren" onClick={() => setIsOpen(false)}>
						Fenster und Türen
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement to="/altbausanierung" onClick={() => setIsOpen(false)}>
						Altbau-Sanierung
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement to="/jobs" onClick={() => setIsOpen(false)}>
						Jobs
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement to="/kontakt" onClick={() => setIsOpen(false)}>
						Kontakt
					</LinkElement>
				</LinkContainer>
			</Navigation>
		</NavigationContainer>
	);
};

const NavigationContainer = styled.nav`
	display: flex;
	width: 100%;
	background-color: ${colors.accent};
	height: 6vh;
	align-items: center;
	justify-content: center;
	position: relative;
	.menu-open {
		opacity: 1;
		transition: opacity 0.2s ease-in-out;
	}

	.menu-close {
		opacity: 1;
		transition: opacity 0.2s ease-in-out;
	}
	.mov-nav-closed {
		transform: scale(1, 0);
		transform-origin: top;
		transition: transform 0.2s ease-in-out;
		li {
			opacity: 0;
			transition: opacity 0.1s ease-in-out;
		}
	}
	.mov-nav-open {
		transform: scale(1, 1);
		transform-origin: top;
		transition: transform 0.3s ease-in-out;
		li {
			opacity: 1;
			transition: opacity 0.2s ease-in-out 0.3s;
		}
	}
	@media (min-width: 1000px) {
		display: none;
	}
`;

const Navigation = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: fit-content;
	position: absolute;
	background-color: ${colors.accent};
	top: 6vh;
	left: 0;
	right: 0;
	z-index: 10;
`;

const LinkContainer = styled.li`
	display: flex;
	width: 50%;
	height: fit-content;
	margin: 1.5rem 0;
`;

const LinkElement = styled(Link)`
	color: ${colors.white};
	text-decoration: none;
	font-size: 1.5rem;
	font-weight: bold;
`;

export default NavBar;
