import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../theme/theme';
import styled from 'styled-components';

const Footer = () => {
	return (
		<FooterContainer>
			<NavigationContainer>
				<Navigation>
					<LinkContainer>
						<LinkElement to="/">| Wir</LinkElement>
					</LinkContainer>
					<LinkContainer>
						<LinkElement to="/renovierung">| Renovierung</LinkElement>
					</LinkContainer>
					<LinkContainer>
						<LinkElement to="/trockenbau">| Trockenbau</LinkElement>
					</LinkContainer>
					<LinkContainer>
						<LinkElement to="/fenstertueren">| Fenster und Türen</LinkElement>
					</LinkContainer>
					<LinkContainer>
						<LinkElement to="/altbausanierung">| Altbau-Sanierung</LinkElement>
					</LinkContainer>
					<LinkContainer>
						<LinkElement to="/kontakt">| Kontakt</LinkElement>
					</LinkContainer>
					<LinkContainer>
						<LinkElement to="/jobs">| Jobs</LinkElement>
					</LinkContainer>
					<LinkContainer>
						<LinkElement to="/impressum">| Impressum</LinkElement>
					</LinkContainer>
				</Navigation>
			</NavigationContainer>
			<p>&copy; 2022 Slavek Kluczynski | Alle Rechte vorbehalten</p>
		</FooterContainer>
	);
};

const FooterContainer = styled.footer`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 27vh;
	background-color: ${colors.footerBackground};
	@media (max-width: 370px) {
		height: 34vh;
	}
	p {
		font-size: 1.2rem;
		color: ${colors.footerText};
		width: 100%;
		text-align: center;
		margin-top: 0.5rem;
	}
`;

const NavigationContainer = styled.nav`
	display: flex;
	width: 100%;
	height: 80%;
	padding: 1.5rem;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const Navigation = styled.ul`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
`;

const LinkContainer = styled.li`
	display: flex;
	height: fit-content;
	margin: 1.5rem 0;
`;

const LinkElement = styled(Link)`
	color: ${colors.footerText};
	text-decoration: none;
	font-size: 1.2rem;
	font-weight: bold;
`;

export default Footer;
