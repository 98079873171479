import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { colors } from '../theme/theme';

const Impressum = () => {
	return (
		<ImpressumContainer>
			<Helmet>
				<title>Slavek Kluczynski | Impressum</title>
				<meta name="description" content="Impressum von Slavek Kluczynski" />
				<meta name="keywords" content="Impressum" />
				<meta name="robots" content="noindex" />
				<link rel="canonical" href="https://slavek-kluczynski.de/impressum" />
			</Helmet>
			<h1>Impressum</h1>

			<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
			<p>
				Slavek Kluczynski
				<br />
				Bauunternemen
				<br />
				Weiherweg 2<br />
				79194 Gundelfingen
			</p>

			<h2>Kontakt</h2>
			<p>
				Telefon: +49 (0) 151 4354 4071
				<br />
				E-Mail: kluczynskislawek4@gmail.com
			</p>

			<h2>Umsatzsteuer-ID</h2>

			<h3>
				Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
				Umsatzsteuergesetz:
			</h3>
			<p>053/653/08421</p>

			<h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>

			<h3>Berufsbezeichnung:</h3>
			<p>Maurer</p>

			<h3>Zust&auml;ndige Kammer:</h3>
			<p>
				Handwerkskammer Freiburg
				<br />
				Bismarckallee 6<br />
				79098 Freiburg i.Br.
			</p>

			<h3>Verliehen in:</h3>
			<p>Baden-W&uuml;rttemberg</p>
			<p>Es gelten folgende berufsrechtliche Regelungen:</p>
			<h2>
				Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
			</h2>
			<p>
				Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
				vor einer Verbraucherschlichtungsstelle teilzunehmen.
			</p>
		</ImpressumContainer>
	);
};

const ImpressumContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	align-items: center;
	padding: 2rem;
	background-color: ${colors.white};
	font-family: 'Montserrat', sans-serif;
	h1 {
		font-size: 2.5rem;
		font-weight: 500;
		color: ${colors.accent};
	}

	h2 {
		font-size: 2rem;
		font-weight: 500;
		color: ${colors.accent};
		margin: 1.5rem 0;
		width: 100%;
		text-align: left;
	}

	h3 {
		font-size: 1.8rem;
		font-weight: 500;
		color: ${colors.black};
		margin: 1rem 0;
		width: 100%;
		text-align: left;
	}

	p {
		font-size: 1.5rem;
		font-weight: 500;
		color: ${colors.black};
		width: 100%;
		text-align: left;
	}
`;

export default Impressum;
