import React from 'react';
import PageSectionCard from './PageSectionCard';
import { colors } from '../theme/theme';

import styled from 'styled-components';

const PageCard = ({ title, content, image }) => {
	return (
		<PageCardContainer>
			<h3>{title}</h3>
			<PageSectionCard content={content} image={image} />
		</PageCardContainer>
	);
};

const PageCardContainer = styled.main`
	display: flex;
	flex-direction: column;
	width: 95%;
	min-height: 75vh;
	padding: 0.5rem;
	margin: 1.5rem 0;
	align-items: center;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
	background-color: ${colors.pageCardBackground};
	@media (min-width: 1000px) {
		padding: 2rem;
	}

	h3 {
		display: flex;
		font-size: 2.5rem;
		margin-bottom: 1rem;
		width: 100%;
		height: 10rem;
		text-align: center;
		justify-content: center;
		align-items: center;
		background-color: ${colors.white};
		color: ${colors.accent};
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
		@media (min-width: 1000px) {
			margin: 1rem 0;
		}
	}
`;

export default PageCard;
