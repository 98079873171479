import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageCard from '../components/PageCard';
import { FensterTuerenContent } from '../content/content';
import styled from 'styled-components';
import { styles, pageTransition } from '../theme/theme';
import background from '../images/fenstertueren.jpg';
import { motion } from 'framer-motion';

const FensterTueren = () => {
	return (
		<FensterTuerenContainer
			initial={pageTransition.initial}
			animate={pageTransition.animate}
			exit={pageTransition.exit}
		>
			<Helmet>
				<title>Slavek Kluczynski | Fenster und Türen</title>
				<meta
					name="description"
					content="Beim Einbau von Türen und Fenstern verfolgen wir eine Methodik, die ein Höchstmaß an Qualität in der Verarbeitung und im Finish gewährleistet. Das Loch wird gebohrt und das Element entfernt, wobei eine gute Technik angewandt wird, die es vermeidet, die Wände zu treffen, um so wenig wie möglich Schaden und Schutt zu verursachen. Anschließend wird die Öffnung geformt. Das Element wird ordnungsgemäß verlegt, nivelliert und abgeflacht, und der Umfang wird mit einer Dämmung zwischen dem Element und der Wand abgedeckt. Zum Schluss wird die gesamte Konstruktion versiegelt und bis ins kleinste Detail profiliert, damit Sie Ihre Tür oder Ihr Fenster genießen können."
				/>
				<meta
					name="keywords"
					content="Fenster, Türen, Tür, Fenster, Türen, Fenster Tür, Fenster Türen, Fenster Tür Türen, Fenster Tür Türen Slavek Kluczynski, Slavek Kluczynski Fenster, Slavek Kluczynski Türen, Slavek Kluczynski Tür, Slavek Kluczynski Fenster Tür, Slavek Kluczynski Fenster Tür Türen, Slavek Kluczynski Fenster Tür Türen Slavek Kluczynski Fenster Tür Türen"
				/>
				<meta name="robots" content="index, follow" />
				<link
					rel="canonical"
					href="https://slavek-kluczynski.de/fenstertueren"
				/>
			</Helmet>
			<PageCard
				title={FensterTuerenContent.title}
				content={FensterTuerenContent.content}
				image={background}
			/>
		</FensterTuerenContainer>
	);
};

const FensterTuerenContainer = styled(motion.div)`
	display: ${styles.container.display};
	flex-direction: ${styles.container.flexDirection};
	min-height: ${styles.container.minHeight};
	max-width: ${styles.container.maxWidth};
	align-items: ${styles.container.alignItems};
	background-image: url(${background});
	background-position: ${styles.container.backgroundPosition};
	background-size: ${styles.container.backgroundSize};
`;

export default FensterTueren;
