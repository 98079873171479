import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageCard from '../components/PageCard';
import { RenovierungContent } from '../content/content';
import styled from 'styled-components';
import { styles, pageTransition } from '../theme/theme';
import background from '../images/renovierung.jpg';
import { motion } from 'framer-motion';

const Renovierung = () => {
	return (
		<RenovierungContainer
			initial={pageTransition.initial}
			animate={pageTransition.animate}
			exit={pageTransition.exit}
		>
			<Helmet>
				<title>Slavek Kluczynski | Renovierung</title>
				<meta
					name="description"
					content="Wir verfügen über eine große Erfahrung in diesem Sektor und arbeiten daran, Ihr Projekt zu verwirklichen, indem wir während des gesamten Reformprozesses ein umfassendes Follow-up durchführen. Wir möchten, dass die Dinge klar und transparent sind. Wir sind ansprechbar und zugänglich. Ihre Zufriedenheit ist unser größter Lohn."
				/>
				<meta
					name="keywords"
					content="Renovierung, Renovierungen, Renovierungen im Freiburg, Renovierungen im Freiburg im Rahmen der Wohnung, Renovierungen im Rahmen der Wohnung"
				/>
				<meta name="robots" content="index, follow" />
				<link rel="canonical" href="https://slavek-kluczynski.de/renovierung" />
			</Helmet>
			<PageCard
				title={RenovierungContent.title}
				content={RenovierungContent.content}
				image={background}
			/>
		</RenovierungContainer>
	);
};

const RenovierungContainer = styled(motion.div)`
	display: ${styles.container.display};
	flex-direction: ${styles.container.flexDirection};
	min-height: ${styles.container.minHeight};
	max-width: ${styles.container.maxWidth};
	align-items: ${styles.container.alignItems};
	background-image: url(${background});
	background-position: ${styles.container.backgroundPosition};
	background-size: ${styles.container.backgroundSize};
`;

export default Renovierung;
