export const HomeContent = {
	title: 'Experten für Innenausbau in der Region Freiburg',
	content: {
		title: '',
		text: 'Wir engagieren uns für eine persönliche und direkte Betreuung am Ort der Projektentwicklung und ersparen dem Kunden ständige und unnötige Reisen zu Handelsbüros und Budgets/technischen Bewertungen, die zu nichts führen. All dies bedeutet, dass wir weitaus weniger Fixkosten haben als andere Unternehmen in diesem Sektor, so dass wir die Gewinnspannen so weit wie möglich zum Vorteil unserer Kunden anpassen können.',
	},
};

export const AltbausanierungContent = {
	title: 'Altbau-Sanierung',
	content: {
		title: 'Wir bringen Ihr Zuhause wieder zum Strahlen',
		text: 'Die Renovierung des alten Hauses ist oft eher eine Notwendigkeit als ein Wunsch nach Veränderung. Manchmal wird mehr Zeit für diese Aufgabe aufgewendet, weil der Renovierung alter Häuser besondere Aufmerksamkeit gewidmet werden muss. Es braucht Zeit, um ein gutes Ergebnis zu erzielen und die ungefähren Kosten einer solchen Renovierung zu verstehen.',
	},
};

export const FensterTuerenContent = {
	title: 'Fenster und Türen',
	content: {
		title: 'Sicherheit, Energieeffizienz und Qualität',
		text: 'Beim Einbau von Türen und Fenstern verfolgen wir eine Methodik, die ein Höchstmaß an Qualität in der Verarbeitung und im Finish gewährleistet. Das Loch wird gebohrt und das Element entfernt, wobei eine gute Technik angewandt wird, die es vermeidet, die Wände zu treffen, um so wenig wie möglich Schaden und Schutt zu verursachen. Anschließend wird die Öffnung geformt. Das Element wird ordnungsgemäß verlegt, nivelliert und abgeflacht, und der Umfang wird mit einer Dämmung zwischen dem Element und der Wand abgedeckt. Zum Schluss wird die gesamte Konstruktion versiegelt und bis ins kleinste Detail profiliert, damit Sie Ihre Tür oder Ihr Fenster genießen können.',
	},
};

export const TrockenbauContent = {
	title: 'Trockenbau',
	content: {
		title: 'Langlebige Struktur und saubere Aufbau',
		text: 'Der Trockenbau ist ein offenes Baumodell, bei dem die Materialien keine nassen Bindemittel für den Zusammenbau von Strukturen oder anderen Komponenten benötigen. Trockenbau und Stahlskelettbau sind zwei der bekanntesten Systeme. Trockenbau ist ein System, bei dem sehr dünne Stahlprofile für die Montage von Innentrennwänden verwendet werden, die keinen strukturellen Belastungen ausgesetzt sind, wie Trennwände, Decken und Verkleidungen.	Stahlskelettbau hingegen wird für den Bau von tragenden Strukturen im Wohnungsbau, für Zwischengeschosse, Außenverkleidungen und Industriebauten (u. a.) verwendet, wobei die verwendeten Profile trotz ihres geringen Gewichts strukturelle Lasten tragen.',
	},
};

export const JobsContent = {
	title: 'Jobs',
	content: {
		title: 'Bewerben Sie sich jetzt!',
		text: 'Haben Sie Erfahrung im Baugewerbe und sind Sie auf der Suche nach einer Stelle? Wenn Sie eine Stelle in der Region Freiburg suchen, nehmen Sie bitte Kontakt mit uns auf. Das Stellenangebot hängt	immer von der Saison ab, es ist also immer möglich, dass wir eine Stelle für Ihr Profil haben.',
		antsprechtTitle: 'Antsprechpartner',
		antsprechtName: 'Herr Slavek Kluczynski',
	},
};

export const KontaktContent = {
	title: 'Kontakt',
	content: {
		title: 'Jetzt ein Angebot anfordern!',
		text: 'Ob Sie uns anrufen oder lieber per WhatsApp kontaktieren möchten, Sie können uns unter folgender Telefonnummer erreichen ',
		phone: '+49 (0) 151 4354 4071',
		phoneMeta: '+4915143544071',
	},
};

export const RenovierungContent = {
	title: 'Renovierung',
	content: {
		title: 'Machen Sie es sich gemütlich',
		text: 'Wir verfügen über eine große Erfahrung in diesem Sektor und arbeiten daran, Ihr Projekt zu verwirklichen, indem wir während des gesamten Reformprozesses ein umfassendes Follow-up durchführen. Wir möchten, dass die Dinge klar und transparent sind. Wir sind ansprechbar und zugänglich. Ihre Zufriedenheit ist unser größter Lohn.',
	},
};
