import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../theme/theme';

const NavBarDesktop = ({ actualPage }) => {
	return (
		<NavigationContainer>
			<Navigation>
				<LinkContainer>
					<LinkElement
						className={actualPage === '/' ? 'active' : 'inactive'}
						to="/"
					>
						Wir
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement
						className={actualPage === '/renovierung' ? 'active' : 'inactive'}
						to="/renovierung"
					>
						Renovierung
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement
						className={actualPage === '/trockenbau' ? 'active' : 'inactive'}
						to="/trockenbau"
					>
						Trockenbau
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement
						className={actualPage === '/fenstertueren' ? 'active' : 'inactive'}
						to="/fenstertueren"
					>
						Fenster und Türen
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement
						className={
							actualPage === '/altbausanierung' ? 'active' : 'inactive'
						}
						to="/altbausanierung"
					>
						Altbau-Sanierung
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement
						className={actualPage === '/jobs' ? 'active' : 'inactive'}
						to="/jobs"
					>
						Jobs
					</LinkElement>
				</LinkContainer>
				<LinkContainer>
					<LinkElement
						className={actualPage === '/kontakt' ? 'active' : 'inactive'}
						to="/kontakt"
					>
						Kontakt
					</LinkElement>
				</LinkContainer>
			</Navigation>
		</NavigationContainer>
	);
};

const NavigationContainer = styled.nav`
	display: flex;
	width: 100%;
	background-color: ${colors.accent};
	height: 6vh;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 3px 10px 3px ${colors.boxShadow};
	z-index: 11;
	@media (max-width: 999px) {
		display: none;
	}
`;

const Navigation = styled.ul`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	max-width: 1000px;
	height: 100%;
	background-color: ${colors.accent};
	z-index: 10;
`;

const LinkContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	height: 100%;
	.active::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 5px;
		background-color: ${colors.white};
	}
`;

const LinkElement = styled(Link)`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	padding: 0.5rem;
	color: ${colors.white};
	background-color: ${colors.accent};
	text-decoration: none;
	text-align: center;
	font-size: 1.7rem;
	font-weight: bold;
	cursor: pointer;
	transition: color 0.3s ease-in-out;
	transition: background-color 0.3s ease-in-out;

	&:hover {
		color: ${colors.accent};
		background-color: ${colors.white};
		transition: color 0.3s ease-in-out;
		transition: background-color 0.3s ease-in-out;
	}
`;

export default NavBarDesktop;
