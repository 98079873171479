import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HomeContent } from '../content/content';
import PageCard from '../components/PageCard';
import background from '../images/wir.jpg';
import styled from 'styled-components';
import { styles, pageTransition } from '../theme/theme';
import { motion } from 'framer-motion';

const Home = () => {
	return (
		<HomeContainer
			initial={pageTransition.initial}
			animate={pageTransition.animate}
			exit={pageTransition.exit}
		>
			<Helmet>
				<title>
					Slavek Kluczynski | Renovierung und Innenausbau rund ums Haus
				</title>
				<meta
					name="description"
					content="Wir engagieren uns für eine persönliche und direkte Betreuung am Ort der Projektentwicklung und ersparen dem Kunden ständige und unnötige Reisen zu Handelsbüros und Budgets/technischen Bewertungen, die zu nichts führen. All dies bedeutet, dass wir weitaus weniger Fixkosten haben als andere Unternehmen in diesem Sektor, so dass wir die Gewinnspannen so weit wie möglich zum Vorteil unserer Kunden anpassen können."
				/>
				<meta
					name="keywords"
					content="Renovierung, Trockenbau, Fenster und Türen, Altbau-Sanierung, Freiburg, Freiburg im Breisgau, Gundelfingen, Baumaister, Maurer, Slavek, Kluczynski, Slavek Kluczynski, Slavek Kluczynski Renovierung, Slavek Kluczynski Trockenbau, Slavek Kluczynski Fenster und Türen, Slavek Kluczynski Fenster und Türen Sanierung, Slavek Kluczynski Altbau-Sanierung, Slavek Kluczynski Freiburg, Slavek Kluczynski Freiburg im Breisgau, Slavek Kluczynski Gundelfingen, Slavek Kluczynski Baumaister, Slavek Kluczynski Maurer, Slavek Kluczynski Slavek, Slavek Kluczynski Kluczynski, Slavek Kluczynski Renovierung, Slavek Kluczynski Trockenbau, Slavek Kluczynski Fenster und Türen, Slavek Kluczynski Fenster und Türen Sanierung, Slavek Kluczynski Altbau-Sanierung, Slavek Kluczynski Freiburg, Slavek Kluczynski Freiburg im Breisgau, Slavek Kluczynski Gundelfingen, Slavek Kluczynski Baumaister, Slavek Kluczynski Maurer, Slavek Kluczynski Slavek, Slavek Kluczynski Kluczynski, Slavek Kluczynski Renovierung, Slavek Kluczynski Trockenbau, Slavek Kluczynski Fenster und Türen, Slavek Kluczynski Fenster und Türen Sanierung, Slavek Kluczynski Altbau-Sanierung, Slavek Kluczynski Freiburg, Slavek Kluczynski Freiburg im Breisgau, Slavek Kluczynski Gundelfingen, Slavek Kluczynski Baumaister, Slavek Kluczynski Maurer, Slavek Kluczynski Slavek, Slavek Kluczynski Kluczynski, Slavek Kluczynski Renovierung, Slavek Kluczynski Trockenbau, Slavek Kluczynski Fenster und Türen, Slavek Kluczynski Fenster und Türen Sanierung, Slavek Kluczynski Altbau-Sanierung, Slavek Kluczynski Freiburg, Slavek Kluczynski Freiburg im Breisgau, Slavek Kluczynski Gundelfingen, Slavek Kluczynski Baumaister, Slavek Kluczynski Maurer, Slavek Kluczynski Slavek,"
				/>
				<meta name="robots" content="index, follow" />
				<link rel="canonical" href="https://slavek-kluczynski.de" />
			</Helmet>
			<PageCard
				title={HomeContent.title}
				content={HomeContent.content}
				image={background}
			/>
		</HomeContainer>
	);
};

const HomeContainer = styled(motion.div)`
	display: ${styles.container.display};
	flex-direction: ${styles.container.flexDirection};
	min-height: ${styles.container.minHeight};
	max-width: ${styles.container.maxWidth};
	background-image: url(${background});
	background-size: $ ${styles.container.backgroundSize};
	align-items: ${styles.container.alignItems};
	background-position: ${styles.container.backgroundPosition};
`;

export default Home;
