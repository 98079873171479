export const colors = {
	accent: '#CA1007',
	black: '#403D3D',
	white: 'white',
	pageCardBackground: 'rgba(255, 255, 255, 0.8)',
	boxShadow: 'rgba(0, 0, 0, 0.8)',
	footerBackground: '#1C1B1B',
	footerText: 'lightgrey',
	whatsapp: '#00a884',
};

export const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: '1000px',
		minHeight: '79vh',
		height: 'fit-content',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
};

export const pageTransition = {
	initial: {
		x: '-100vw',
	},
	animate: {
		x: '0',
	},
	exit: {
		x: '100vw',
		transition: {
			duration: 0.3,
		},
	},
};
