import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import NavBar from './components/NavBar';
import NavBarDesktop from './components/NavBarDesktop';
import Footer from './components/Footer';
import Home from './pages/Home';
import Renovierung from './pages/Renovierung';
import Trockenbau from './pages/Trockenbau';
import FensterTueren from './pages/FensterTueren';
import Altbausanierung from './pages/Altbausanierung';
import Kontakt from './pages/Kontakt';
import Jobs from './pages/Jobs';
import Impressum from './pages/Impressum';
import styled from 'styled-components';

import { AnimatePresence } from 'framer-motion';

function App() {
	const location = useLocation();
	const [actualPage, setActualPage] = React.useState(location.pathname);

	React.useEffect(() => {
		setActualPage(location.pathname);
	}, [location]);

	return (
		<AppContainer>
			<Header />
			<NavBar />
			<NavBarDesktop actualPage={actualPage} />
			<AnimatePresence>
				<Routes location={location} key={location.pathname}>
					<Route path="/" element={<Home />} />
					<Route path="/renovierung" element={<Renovierung />} />
					<Route path="/trockenbau" element={<Trockenbau />} />
					<Route path="/fenstertueren" element={<FensterTueren />} />
					<Route path="/altbausanierung" element={<Altbausanierung />} />
					<Route path="/kontakt" element={<Kontakt />} />
					<Route path="/jobs" element={<Jobs />} />
					<Route path="/impressum" element={<Impressum />} />
				</Routes>
			</AnimatePresence>
			<Footer />
		</AppContainer>
	);
}

const AppContainer = styled.div`
	display: flex;
	min-height: 100vh;
	width: 100%;
	flex-direction: column;
	align-items: center;
`;

export default App;
