import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageCard from '../components/PageCard';
import styled from 'styled-components';
import { styles, pageTransition } from '../theme/theme';
import background from '../images/kontakt.jpg';
import { KontaktContent } from '../content/content';
import { motion } from 'framer-motion';

const Kontakt = () => {
	return (
		<KontaktContainer
			initial={pageTransition.initial}
			animate={pageTransition.animate}
			exit={pageTransition.exit}
		>
			<Helmet>
				<title>Slavek Kluczynski | Kontakt</title>
				<meta
					name="description"
					content="Jetzt ein Angebot anfordern! Ob Sie uns anrufen oder lieber per WhatsApp kontaktieren möchten, Sie können uns unter folgender Telefonnummer erreichen"
				/>
				<meta
					name="keywords"
					content="Kontakt, Vorschlag, Angebot, Renovierung, Trockenbau, Fenster und Türen, Altbau-Sanierung, Freiburg, Freiburg im Breisgau, Gundelfingen, Baumaister, Maurer, Slavek, Kluczynski, Slavek Kluczynski, Slavek Kluczynski Renovierung, Slavek Kluczynski Trockenbau, Slavek Kluczynski Fenster und Türen, Slavek Kluczynski Fenster und Türen Sanierung, Slavek Kluczynski Altbau-Sanierung, Slavek Kluczynski Freiburg, Slavek Kluczynski Freiburg im Breisgau, Slavek Kluczynski Gundelfingen, Slavek Kluczynski Baumaister, Slavek Kluczynski Maurer, Slavek Kluczynski Slavek, Slavek Kluczynski Kluczynski, Slavek Kluczynski Renovierung, Slavek Kluczynski Trockenbau, Slavek Kluczynski Fenster und Türen, Slavek Kluczynski Fenster und Türen Sanierung, Slavek Kluczynski Altbau-Sanierung, Slavek Kluczynski Freiburg, Slavek Kluczynski Freiburg im Breisgau, Slavek Kluczynski Gundelfingen, Slavek Kluczynski Baumaister, Slavek Kluczynski Maurer, Slavek Kluczynski Slavek, Slavek Kluczynski Kluczynski, Slavek Kluczynski Renovierung, Slavek Kluczynski Trockenbau, Slavek Kluczynski Fenster und Türen, Slavek Kluczynski Fenster und Türen Sanierung, Slavek Kluczynski Altbau-Sanierung, Slavek Kluczynski Freiburg, Slavek Kluczynski Freiburg im Breisgau, Slavek Kluczynski Gundelfingen, Slavek Kluczynski Baumaister, Slavek Kluczynski Maurer, Slavek Kluczynski Slavek, Slavek Kluczynski Kluczynski, Slavek Kluczynski Renovierung, Slavek Kluczynski Trockenbau, Slavek Kluczynski Fenster und Türen, Slavek Kluczynski Fenster und Türen Sanierung, Slavek Kluczynski Altbau-Sanierung, Slavek Kluczynski Freiburg, Slavek Kluczynski Freiburg im Breisgau, Slavek Kluczynski Gundelfingen, Slavek Kluczynski Baumaister, Slavek Kluczynski Maurer, Slavek Kluczynski Slavek,"
				/>
				<meta name="robots" content="index, follow" />
				<link rel="canonical" href="https://slavek-kluczynski.de/kontakt" />
			</Helmet>
			<PageCard
				title={KontaktContent.title}
				content={KontaktContent.content}
				image={background}
			/>
		</KontaktContainer>
	);
};

const KontaktContainer = styled(motion.div)`
	display: ${styles.container.display};
	flex-direction: ${styles.container.flexDirection};
	min-height: ${styles.container.minHeight};
	max-width: ${styles.container.maxWidth};
	align-items: ${styles.container.alignItems};
	background-image: url(${background});
	background-position: ${styles.container.backgroundPosition};
	background-size: ${styles.container.backgroundSize};
	background-color: red;
`;

export default Kontakt;
